


























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { copyToClipboard, Notify } from "quasar";

@Component({
  components: {},
})
export default class Publicacion extends Vue {
  private nombreWeb: any = "";
  private dataNoticia: any = "";
  private reproducir: boolean = true;
  private speechSynthesisinwindow = false;
  private voice: SpeechSynthesisUtterance | null = null;
  private speaking: boolean = false;

  private mounted() {
    if ("speechSynthesis" in window) {
      this.speechSynthesisinwindow = true;
    }
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoNoticia();
    }
  }

  private getInfoNoticia() {
    this.$axios
      .get("noticias/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.dataNoticia = res.data;
        this.visitaNoticia();
        if (this.dataNoticia.fotos.image_fulltext) {
          this.dataNoticia.fotos.image_fulltext =
            base + this.dataNoticia.fotos.image_fulltext;
        } else {
          this.dataNoticia.fotos.image_fulltext = base + "Noticias/default.jpg";
        }
        if (this.dataNoticia.fotos.image_intro) {
          this.dataNoticia.fotos.image_intro =
            base + this.dataNoticia.fotos.image_intro;
        } else {
          this.dataNoticia.fotos.image_intro =
            base + "media/imagenes/2021/08/logo-maipu.jpeg";
        }
        //MetaData generica
        document.title =
          this.dataNoticia.titulo + " - Ilustre Municipalidad de Punta Arenas";
        (document.querySelector(
          'meta[name="description"]'
        ) as any).setAttribute("content", this.dataNoticia.descripcion_corta);
        (document.querySelector('meta[name="image"]') as any).setAttribute(
          "content",
          this.dataNoticia.fotos.image_intro
        );

        //MetaData Twitter
        (document.querySelector(
          'meta[name="twitter:title"]'
        ) as any).setAttribute(
          "content",
          this.dataNoticia.titulo + " - Ilustre Municipalidad de Punta Arenas"
        );
        (document.querySelector(
          'meta[name="twitter:description"]'
        ) as any).setAttribute("content", this.dataNoticia.descripcion_corta);
        (document.querySelector(
          'meta[name="twitter:image:src"]'
        ) as any).setAttribute("content", this.dataNoticia.fotos.image_intro);

        //MetaData Facebook
        (document.querySelector(
          'meta[property="og:title"]'
        ) as any).setAttribute(
          "content",
          this.dataNoticia.titulo + " - Ilustre Municipalidad de Punta Arenas"
        );
        (document.querySelector(
          'meta[property="og:description"]'
        ) as any).setAttribute("content", this.dataNoticia.descripcion_corta);
        (document.querySelector(
          'meta[property="og:image"]'
        ) as any).setAttribute("content", this.dataNoticia.fotos.image_intro);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public speak(title: string, content: string) {
    if ("speechSynthesis" in window) {
      this.speaking = true;

      let textoSinEtiquetas = content.replace(/<\/?[^>]+>/g, "");
      const textoDecodificado = textoSinEtiquetas
        .replace(/&nbsp;/g, " ")
        .replace(/&aacute;/g, "á")
        .replace(/&eacute;/g, "é")
        .replace(/&iacute;/g, "í")
        .replace(/&oacute;/g, "ó")
        .replace(/&uacute;/g, "ú")
        .replace(/&ntilde;/g, "ñ")
        .replace(/&Aacute;/g, "Á")
        .replace(/&Eacute;/g, "É")
        .replace(/&Iacute;/g, "Í")
        .replace(/&Oacute;/g, "Ó")
        .replace(/&Uacute;/g, "Ú")
        .replace(/&Ntilde;/g, "Ñ")
        .replace(/&uuml;/g, "ü")
        .replace(/&ldquo;/g, "“")
        .replace(/&rdquo;/g, "”");

      this.voice = new SpeechSynthesisUtterance();
      this.voice.lang = "es-MX";
      this.voice.rate = 1;
      this.voice.pitch = 1;
      this.voice.text = `${title}. ${textoDecodificado}`;

      this.voice.onstart = () => {
        // console.log("      window.speechSynthesis;", window.speechSynthesis);
        this.speaking = true;
      };

      this.voice.onend = () => {
        this.speaking = false;
      };

      // console.log(this.voice);
      window.speechSynthesis.speak(this.voice);
    }
  }

  public cancelVoice() {
    if ("speechSynthesis" in window) {
      window.speechSynthesis.cancel();
      this.speaking = false;
    }
  }

  private visitaNoticia() {
    this.$axios
      .put("noticias/visita", {
        id: this.dataNoticia.id,
      })
      .then((res: any) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);
    const hora = fechaInicio.substring(11, 16);

    if (mesInicio == 1) {
      mesInicio = "enero";
    } else if (mesInicio == 2) {
      mesInicio = "febrero";
    } else if (mesInicio == 3) {
      mesInicio = "marzo";
    } else if (mesInicio == 4) {
      mesInicio = "abril";
    } else if (mesInicio == 5) {
      mesInicio = "mayo";
    } else if (mesInicio == 6) {
      mesInicio = "junio";
    } else if (mesInicio == 7) {
      mesInicio = "julio";
    } else if (mesInicio == 8) {
      mesInicio = "agosto";
    } else if (mesInicio == 9) {
      mesInicio = "septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "octubre";
    } else if (mesInicio == 11) {
      mesInicio = "noviembre";
    } else {
      mesInicio = "diciembre";
    }

    if (mesInicio != 0) {
      return (
        diaInicio + " de " + mesInicio + " " + anhoInicio + " a las " + hora
      );
    }
  }
  private copiarClipboard() {
    copyToClipboard(window.location.href)
      .then(() => {
        Notify.create({
          color: "positive",
          message: "Se ha copiado en el Link correctamente.",
          icon: "report_problem",
          position: "top",
          timeout: 2500,
        });
        return;
      })
      .catch(() => {
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un problema. Por favor, inténtelo de nuevo.",
          icon: "report_problem",
          position: "top",
          timeout: 2500,
        });
        return;
      });
  }
  private irLink() {
    var link =
      "https://web.whatsapp.com/send?l=en&text=" +
      encodeURIComponent(window.location.href);
    window.open(link);
  }

  private irLinkMobile() {
    var link =
      "https://api.whatsapp.com/send?text=" +
      encodeURIComponent(window.location.href);
    window.open(link);
  }

  private irEmail(titulo: string, contenido: string) {
    window.open(
      "mailto:?Subject=" +
        titulo +
        "&" +
        "Body=" +
        encodeURIComponent(window.location.href)
    );
  }

  private irNoticia(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Publicacion" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Publicacion",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categoria" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Categoria",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
}
